import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "faqs-container" }
const _hoisted_2 = { class: "faqs-content" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_CollapseListItem = _resolveComponent("CollapseListItem")!
  const _component_CollapseList = _resolveComponent("CollapseList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, { defaultHeader: true }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('header.faqs')), 1)
        ]),
        _: 1
      }),
      (_ctx.faqs && _ctx.faqs.length > 0)
        ? (_openBlock(), _createBlock(_component_CollapseList, { key: 0 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq) => {
                return (_openBlock(), _createBlock(_component_CollapseListItem, {
                  key: faq.uid,
                  title: faq.question
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: faq.answer
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}